import React from 'react'

const Scan = (props) => {
    const { qrData } = props;

    return (
        <>
            <div>
                <h1>Scan My QR Code</h1>
            </div>
            {
                qrData ? (
                    <div style={{
                        margin:2
                    }}>
                        <img src={qrData.qrCode} alt="" />
                    </div>
                ) : null
            }
        </>
    )
}

export default Scan;