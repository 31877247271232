import axios from "axios";
import "./App.css";
import Scan from "./pages/scan";
import { useEffect, useState } from "react";

function App() {
  const [qrData, setQRData] = useState(null);

  // console.log(window.location.pathname.split("/")[1]);
  const id = window.location.pathname.split("/")[1];

  const getQrData = async () => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Authorization:
        "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOjI5NiwibW9iaWxlTnVtYmVyIjoiOTk2NzI0MzY0NiIsImlhdCI6MTY4MjA2NTM1NH0.Qqnqt-s-jMAhP-65wTX4SkDHYYyNjZRfmGQXXeOiNmc",
    };
    try {
      const { data } = await axios.get(
        `https://api.parkit.biz/v1/society/guest/qrcode?id=${id}`,
        { headers }
      );
      setQRData(data);
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    getQrData();
  }, []);

  return (
    <div className="App">
      <Scan qrData={qrData} />
    </div>
  );
}

export default App;
